<template>
  <div>
    <portal to="body-top">
      <h3 class="text-center font-weight-bolder mb-1">
        {{ $t(CREATE_PAGE_TITLE_PREFIX) }}
        —
        {{ getValueFromGivenObjectByKey(assetItem, 'product.sku') }}
        {{ getValueFromGivenObjectByKey(assetItem, 'product.name') }}
      </h3>
    </portal>
    <request-service-quote-form />
  </div>
</template>

<script>
import { getValueFromGivenObjectByKey } from '@/@core/utils/utils'
import RequestServiceQuoteForm from '../components/create/request-service-quote/Form.vue'
// eslint-disable-next-line import/no-cycle
import config from '../config'

export default {
  name: 'RequestServiceQuote',
  components: {
    RequestServiceQuoteForm,
  },
  computed: {
    assetItem() {
      return this.$store.state[this.MODULE_NAME].assetItem
    },
  },
  created() {
    this.$store.commit(`${this.MODULE_NAME}/GET`, {})
    this.$store.commit(`${this.MODULE_NAME}/SET_DISPATCH_AND_RETURN_FORM`, {})
    this.$store.commit(`${this.MODULE_NAME}/SET_SERVICE_ORDER_INFORMATION_FORM`, {})
    this.$store.commit(`${this.MODULE_NAME}/SET_ATTACHMENTS`, [])

    this.$store.dispatch(`${this.MODULE_NAME}/getTestDamagedAsset`, { id: this.$route.params.id })
      .then(({ data: { data } }) => {
        this.$store.commit(`${this.MODULE_NAME}/SET_ATTACHMENTS`, data.damaged.attachments || [])
      })
  },
  methods: { getValueFromGivenObjectByKey },
  setup() {
    const {
      MODULE_NAME,
      CREATE_PAGE_TITLE_PREFIX,
      CREATE_PAGE_TITLE_SUFFIX,
    } = config()

    return {
      MODULE_NAME,
      CREATE_PAGE_TITLE_PREFIX,
      CREATE_PAGE_TITLE_SUFFIX,
    }
  },
}
</script>
